



























































import { Component, Vue } from 'vue-property-decorator'
import GlobalMenu from '@/components/GlobalMenu'
import PageView from './PageView.vue'
import defaultLogo from '@/assets/logo.png'
@Component({
  components: {
    GlobalMenu,
    PageView,
  },
})
export default class extends Vue {
  mounted() {}
  defaultLogo: any = defaultLogo

  get userInfo() {
    return this.$store.state.user.userInfo
  }
  //current: string | undefined = this.getCurrentUrl()
  navigationList: any = [
    {
      name: '年度计划',
      key: '/yearPlan/index',
      path: '/yearPlan',
    },
    {
      name: '月度计划',
      key: '/monthPlan/index',
      path: '/monthPlan',
    },
    {
      name: '周计划',
      key: '/weekPlan/index',
      path: '/yearPlan',
    },
    {
      name: '日计划',
      key: '/dailyPlan/index',
      path: '/dailyPlan',
    },
  ]
  get getCurrentUrl() {
    if (this.$route.meta) {
      if (this.$route.meta.title.indexOf('年') !== -1) {
        return '/yearPlan/index'
      } else if (this.$route.meta.title.indexOf('月') !== -1) {
        return '/monthPlan/index'
      } else if (this.$route.meta.title.indexOf('周') !== -1) {
        return '/weekPlan/index'
      } else if (this.$route.meta.title.indexOf('日') !== -1) {
        return '/dailyPlan/index'
      }
    }
  }
  handleChange(value: any) {
    this.$router.push(value)
  }
  logout() {
    this.$store.dispatch('user/logout').then(() => {
      this.$router.push('/login')
    })
  }

  get userName() {
    return this.$store.state.user.name
  }
  get userAvatar() {
    return this.$store.state.user.userInfo.avatar
  }
}
