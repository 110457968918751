import { GET, POST, PUT, DELETE } from '@/config/fetch'

// 日计划列表
export const dailyPlanList = (params: any) =>
  GET('/front/dailylog/v1/dailyPlan/list', params)

// 新增日计划
export const addDailyPlan = (params: any) =>
  POST('/front/dailylog/v1/dailyPlan', params)

// 编辑日计划
export const editDailyPlan = (params: any) =>
  PUT(`/front/dailylog/v1/dailyPlan/${params.id}`, params)

// 编辑日计划提交状态
export const editDailyPlanSubmit = (id: string, isSubmit: number) =>
  PUT(`/front/dailylog/v1/dailyPlan/updateSubmitStatus/${id}`, { id, isSubmit })

// 日计划详情
export const dailyPlanDetail = (id: string) =>
  GET(`/front/dailylog/v1/dailyPlan/${id}`)

// 编辑日计划进度
export const editDailyPlanProcess = (id: string, finishProgress: number) =>
  PUT(`/front/dailylog/v1/dailyPlan/updateFinishProgress/${id}`, {
    finishProgress,
  })

// 自我评价
export const updateEvaluation = (params: any) =>
  PUT(`/front/dailylog/v1/dailyPlan/updateEvaluation/${params.id}`, params)

// 日计划导出
export const exportDailyPlan = (params: any) =>
  GET(
    `/front/dailylog/v1/dailyPlan/exportExcel`,
    { ...params, fileName: '日计划' },
    {
      responseType: 'blob',
    },
  )

// 查询日历事件
export const getCalendarEvent = (planDate: string) =>
  GET('/front/dailylog/v1/dailyPlan/getRecordedDateList', { planDate })

// 日计划关键字搜索列表
export const searchPageList = (params: any) =>
  GET('/front/dailylog/v1/dailyPlan/searchPageList', params)

// 删除计划
export const delPlan = (id: string) =>
  DELETE(`/front/dailylog/v1/dailyPlan/${id}`)

// 当月日计划数量、字数及上月日计划数量及字数
export const dailyPlanStat = () =>
  GET('/front/dailylog/v1/dailyPlan/statistics')
