













































































import { Component, Vue,  } from 'vue-property-decorator'
import { getPersonDayStatisticsData, getPersonWeekStatisticsData } from '@/api/statistics'
import DailyPlan from './components/DailyPlan.vue'
import * as echarts from 'echarts'
import moment from 'moment'

@Component({
  components: {
    DailyPlan,
  },
})
export default class ECharts extends Vue {
  get getDay() {
    const day = moment().subtract(1, 'days')
    return day.format(`M月DD日（YYYY年）`)
  }
  get getWeek() {
    // 获取上周周一
    const dateStart = moment()
      .subtract(1, 'week')
      .startOf('week')
      .format('M月DD日')
    // 获取上周周日
    const dateEnd = moment().subtract(1, 'week').endOf('week').format('M月DD日')
    return dateStart + '-' + dateEnd + ' （' + moment().format('YYYY年') + '）'
  }
  private dayData: any = [1, 2]
  private weekDate: any = [1, 2]
  private initRender() {
    const mainEleDay = this.$refs.day
    const mainWeek = this.$refs.week
    const myWeekChart = echarts.init(mainWeek as HTMLBRElement)
    myWeekChart.setOption({
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          name: '周计划',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          color: ['#1381ff', '#f8d038'],
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: this.weekDate,
        },
      ],
    })
    const myChart = echarts.init(mainEleDay as HTMLBRElement)
    myChart.setOption({
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          name: '日计划',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          color: ['#1381ff', '#f8d038'],
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: this.dayData,
        },
      ],
    })
  }
  private mounted() {
    this.getRenderDate()
    this.getRenderWeek()
  }
  private async getRenderDate() {
    const [err, res] = await getPersonDayStatisticsData()
    if (!err) {
      this.dayData = res
      this.initRender()
    }
  }
  private async getRenderWeek() {
    const [err, res] = await getPersonWeekStatisticsData()
    if (!err) {
      this.weekDate = res
      this.initRender()
    }
  }
}
