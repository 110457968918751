<template>
  <div class="user-select-wrapper">
    <div class="responsiblePerson">
      <div class="tag-wrapper">
        <a-tag
          class="tag"
          size="small"
          v-for="user in selectedList"
          :key="user.id"
          :closable="
            disabledUserList.findIndex((item) => item.id === user.id) < 0
          "
          @close="handleRemove(user)"
        >
          {{ user.name || user.realName }}
        </a-tag>
      </div>
      <img
        v-if="selectedList.length > 0"
        class="close-icon"
        src="@/assets/address_book_img/icon_celect_delete.svg"
        @click="handleEmpty"
      />
    </div>
    <div class="user-select-content">
      <div class="left">
        <a-tree
          v-show="!treeLoading"
          defaultExpandAll
          :treeData="orgTree"
          :expandedKeys.sync="expandedKeys"
          :selectedKeys="[currentNodeKey]"
          :replaceFields="{ title: 'name', key: 'id' }"
          @select="handleSelectTree"
        >
        </a-tree>
        <div
          v-show="treeLoading"
          style="
            height: 300px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <a-spin />
        </div>
      </div>
      <div class="right">
        <a-input
          placeholder="输入姓名搜索"
          v-model="filterText"
          @input="handleFilterChange"
          allowClear
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <div style="margin-top: 10px" v-show="currentList.length > 1">
          <a-checkbox v-model="checkAll"> 全选 </a-checkbox>
        </div>
        <div class="user-list">
          <template v-if="currentList.length > 0">
            <div class="user-item" v-for="ele in currentList" :key="ele.id">
              <div class="user-row">
                <a-checkbox
                  style="margin-right: 8px"
                  :disabled="
                    disabledUserList.findIndex((item) => item.id === ele.id) >
                      -1
                  "
                  :checked="
                    selectedList
                      .map((item) => item.id)
                      .join(',')
                      .includes(ele.id)
                  "
                  @change="(val) => handleSelect(ele, val)"
                >
                  <div class="flex-between">
                    <a-avatar
                      class="avatar"
                      :size="24"
                      :src="ele.headSculpture"
                    >
                      <img
                        style="width: 24px; height: 24px"
                        src="@/assets/img/头像 拷贝.png"
                      />
                    </a-avatar>
                    <span class="user-name" :title="ele.name">
                      {{ ele.name }}
                    </span>
                  </div>
                </a-checkbox>
              </div>
            </div>
          </template>
          <a-empty v-else style="margin-left: 100px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { intersectionBy, unionBy, xorBy, cloneDeep } from 'lodash-es'
import { getUserOrgTree } from '@/api/common'
import { getHasAuthUserTree } from '@/api/statistics'
export default {
  components: {},
  props: {
    // 单选
    singleSelect: {
      type: Boolean,
      default: false,
    },
    // 单选时回显的用户
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    // 多选时回显的用户列表
    userList: {
      type: Array,
      default: () => [],
    },
    // 禁止选择的用户，同时不可删除
    disabledUserList: {
      type: Array,
      default: () => [],
    },
    authTree: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      treeLoading: false,
      expandedKeys: [],
      orgTree: [],
      treeData: [],
      filterText: '',
      currentNodeKey: '',
      currentNode: null,
      currentList: [],
      flattenOrgList: [],
      flattenUserList: [],
      submiting: false,
      selectedList: [],
    }
  },
  //   watch: {
  //     dialogVisible(nval) {
  //       if (nval) {
  //         this.getSysOrgByToken()
  //         this.$nextTick(() => {
  //           this.filterText = ''
  //           this.selectedList = []
  //         })
  //       }
  //     },
  //   },
  computed: {
    checkAll: {
      get() {
        const intersection = intersectionBy(
          this.currentList,
          this.selectedList,
          'id',
        )
        return intersection.length === this.currentList.length
      },
      set(flag) {
        if (flag) {
          this.selectedList = unionBy(
            [
              ...this.selectedList,
              ...this.currentList,
              ...this.disabledUserList,
            ],
            'id',
          )
        } else {
          const orList = xorBy(this.selectedList, this.currentList, 'id')
          this.selectedList = unionBy(
            [...orList, ...this.disabledUserList],
            'id',
          )
        }
      },
    },
  },
  methods: {
    // 将组织和用户打平
    flattenOrg(arr, parent = null) {
      arr.forEach((item) => {
        if (item.type === 'org') {
          item.parent = parent
          this.flattenOrgList.push(item)
        } else if (item.type === 'user') {
          if (!this.flattenUserList.find((user) => user.id === item.id)) {
            this.flattenUserList.push(item)
          }
        }
        if (item.children) {
          this.flattenOrg(item.children, item)
        }
      })
    },
    filterOrg(arr, parent = null) {
      return arr
        .filter((item) => item.type === 'org')
        .map((item) => {
          const newItem = {
            ...item,
            parent,
          }
          if (newItem.children && newItem.children.length > 0) {
            newItem.children = this.filterOrg(newItem.children, item)
          }
          return newItem
        })
    },
    findOrgUser(orgId) {
      const org = this.flattenOrgList.find((item) => item.id === orgId)
      if (!org) return []
      const userList = []
      function findUser(arr) {
        arr.forEach((item) => {
          if (item.type === 'user') {
            userList.push(item)
          } else if (item.children) {
            findUser(item.children)
          }
        })
      }
      findUser(org.children ?? [])
      return unionBy(userList, 'id')
    },
    // 搜索人员或组织
    handleFilterChange() {
      if (this.filterText === '') {
        // this.currentList = [...this.flattenUserList]
        this.handleCurrentNodeChange(this.currentNode)
        return
      }
      this.currentList = this.currentList.filter((item) =>
        item.name.includes(this.filterText),
      )
    },
    handleSelectTree(selectedKeys, e) {
      // this.currentNodeKey = e.dataRef.id
      this.handleCurrentNodeChange(e.node.dataRef)
    },
    // 选中某个节点
    handleCurrentNodeChange(data) {
      const { id } = data
      if (id) {
        this.currentNodeKey = id
        this.currentNode = data
        this.currentList =
          id === this.orgTree[0].id
            ? [...this.flattenUserList]
            : this.findOrgUser(id)
        // this.flattenUserList.filter((item) => {
        //     return !!item.orgList?.find((org) => org.id === id)
        //   })
      } else {
        this.currentNodeKey = ''
        this.currentNode = null
        this.currentList = [...this.flattenUserList]
      }
      if (this.filterText) {
        this.handleFilterChange()
      }
    },
    // 选择人员
    handleSelect(data, checked) {
      const index = this.selectedList.findIndex((item) => item.id === data.id)
      if (checked && index < 0) {
        if (this.singleSelect) {
          this.selectedList = [{ ...data }]
        } else {
          this.selectedList.push({
            ...data,
          })
        }
      } else {
        if (index > -1) {
          this.selectedList.splice(index, 1)
        }
      }
    },
    // 删除人员
    handleRemove(data) {
      const index = this.selectedList.findIndex((item) => item.id === data.id)
      if (index > -1) {
        this.selectedList.splice(index, 1)
      }
    },
    // 回显
    setCheckedNodes() {
      if (this.singleSelect) {
        if (this.currentUser.id) {
          const user = this.flattenUserList.find(
            (item) => item.id === this.currentUser.id,
          )
          if (user) {
            this.selectedList = [{ ...user }]
          }
        } else {
          this.selectedList = []
        }
      } else {
        this.selectedList = this.userList
          .map((item) => {
            return this.flattenUserList.find((u) => u.id === item.id)
          })
          .filter((item) => !!item)
      }
    },
    // 查询组织树
    async getSysOrgByToken() {
      this.treeLoading = true
      const remoteMethods = this.authTree ? getHasAuthUserTree : getUserOrgTree
      const [err, res] = await remoteMethods()
      this.treeLoading = false
      if (err) return
      this.orgTree = this.filterOrg(cloneDeep(res))
      this.flattenOrg(res)
      this.handleCurrentNodeChange(res[0])
      this.treeData = res
      this.setCheckedNodes()
      this.expandedKeys = [res[0].id]
    },

    handleEmpty() {
      this.selectedList = []
      this.selectedList = this.disabledUserList
        .map((item) => {
          return this.flattenUserList.find((u) => u.id === item.id)
        })
        .filter((item) => !!item)
    },
    getSelectedList() {
      return [...this.selectedList]
    },
  },
  mounted() {
    this.getSysOrgByToken()
    this.$nextTick(() => {
      this.filterText = ''
      this.selectedList = []
    })
  },
}
</script>

<style lang="less" scoped>
.flex-between {
  display: flex;
  justify-content: space-between;
}

.user-select-wrapper {
  & ::v-deep {
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      border-radius: 0;
      border-color: #f2f2f2;
      .el-input__icon {
        line-height: 32px !important;
      }
    }
    .el-select .el-input__inner {
      padding-left: 10px;
      padding-right: 10px;
    }
    .el-select .el-input {
      background: transparent;
    }
  }
}

.user-select-content {
  display: flex;
  border: 1px solid #f2f2f2;
  min-height: 300px;
  .left {
    width: 40%;
    flex: 0 0 40%;
    border-right: 1px solid #f2f2f2;
    height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  .right {
    width: 60%;
    flex: 0 0 60%;
    padding: 15px 15px 0 15px;
    align-items: center;
    .user-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      padding-top: 10px;
      height: 410px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
      ::v-deep .ant-avatar-string {
        transform: scale(1) !important;
        left: 0 !important;
        top: -1px;
      }
    }
  }
  .user-item {
    width: 25%;
    flex: 0 0 25%;
    margin: 4px 0;
    ::v-deep {
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
      }
      .ant-checkbox {
        display: flex;
        align-items: center;
      }
    }
    .user-name {
      margin-left: 8px;
      cursor: pointer;
      width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.responsiblePerson {
  min-height: 40px;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 10px 0;
  border: 1px solid rgba(220, 223, 230, 1);
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
  }
  .tag-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 150px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  .tag {
    margin-bottom: 10px;
  }
  .close-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -5px;
    cursor: pointer;
  }
}
</style>
