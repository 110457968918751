























































































































































































































































































import MyTinymce from '@/components/Editor/index.vue'
import { Component, Ref, Mixins } from 'vue-property-decorator'
import Share from '@/components/Share/index.vue'
import { addMonthPlan, exportMonthPlan } from '@/api/month'
import moment from 'moment'
import Commit from '@/components/comment/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
import { uuid } from '@/utils/util'
import MonthMixin from './mixins'

moment.locale('zh-cn', {
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY-MM-DD',
    LL: 'YYYY年MM月DD日',
    LLL: 'YYYY年MM月DD日Ah点mm分',
    LLLL: 'YYYY年MM月DD日ddddAh点mm分',
    l: 'YYYY-M-D',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日dddd HH:mm',
  },
})

@Component({
  components: {
    MyTinymce,
    Commit,
    ThumbsUp,
    Share,
  },
})
export default class Month extends Mixins(MonthMixin) {
  private thisYear: string = new Date().getFullYear().toString()
  private dataYear: string = new Date().getFullYear().toString()
  private currentMonth: string = (new Date().getMonth() + 1).toString()
  private yearShowOne: boolean = false
  private currentPlanIndex: number = 0
  private submitLoading: boolean = false
  private isExitHover: boolean = false
  private exportLoading: boolean = false
  currentPlanId: string = ''
  currentStatus: string = 'done'
  private currentHoverIndex: Number = -1

  fileList: Object[] = [
    {
      name: '1.jpg',
    },
  ]
  private formData: any = {
    uuid: uuid(),
    title: '',
    content: '',
    planYear: new Date().getFullYear().toString(),
    planMonth: (new Date().getMonth() + 1).toString(),
    businessAttachmentList: [],
    shareList: [],
    isSubmit: 0,
  }
  private oldForm: any = {
    title: '',
    content: '',
    businessAttachmentList: [],
    isSubmit: 0,
  }
  private planData: any = []

  //编辑样式
  isEditBtn(index: any) {
    this.isExitHover = true
    this.currentHoverIndex = index
  }
  isNoEditBtn() {
    this.isExitHover = true
    this.currentHoverIndex = -1
  }

  //给分享人赋值
  addShareList(e: any) {
    this.formData.shareList = e
  }

  //导出月计划
  private async handleExport() {
    this.exportLoading = true
    await exportMonthPlan({
      planYear: this.thisYear,
      planMonth: this.currentMonth,
    })
    this.exportLoading = false
  }

  // 弹出日历和关闭日历的回调
  private openChangeOne(status: any) {
    if (status) {
      this.yearShowOne = true
    } else {
      this.yearShowOne = false
    }
  }

  @Ref('autoSaveRef')
  autoSaveRef: any
  get showAutoSave() {
    return (
      !this.formData.id || (this.formData.id && this.formData.isSubmit == 0)
    )
  }
  async handleAutoSave(syncTimeFlag?: boolean) {
    console.log(
      'handleAutoSave',
      syncTimeFlag,
      this.showAutoSave,
      this.formData,
    )
    const syncTime = typeof syncTimeFlag === 'boolean' && syncTimeFlag === true
    if (!this.showAutoSave) return
    const { content } = this.formData
    if (!content) {
      if (!syncTime) {
        this.autoSaveRef?.setAutoCheck(false)
      }
      return
    }
    const formData = {
      ...this.formData,
      businessAttachmentList:
        this.formData.businessAttachmentList?.map((item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        })) ?? [],
    }
    const currentIndex = this.currentPlanIndex
    const [err, res] = await addMonthPlan({ ...formData, isSubmit: 0 })
    if (err) {
      if (!syncTime) {
        this.autoSaveRef?.setAutoCheck(false)
      }
      return
    }

    if (!formData.id) {
      this.$set(this.planData, currentIndex, { ...this.formData, id: res })
    }

    // 第一次保存或修改当前计划时，刷新计划列表
    // if ((!formData.id || this.currentPlanId === formData.id) && !syncTime) {
    if (!formData.id || this.currentPlanId === formData.id) {
      await this.getMonthPlanListByMonth(
        this.thisYear,
        this.currentMonth,
        false,
      )
    }
    if (!this.formData.id) {
      this.formData.id = res
    }
    if (!syncTime) {
      this.autoSaveRef?.setAutoCheck()
    }
  }
  async stopAutoCheckAndAutoSave() {
    this.autoSaveRef?.stopAutoCheck()
    await this.handleAutoSave(true)
  }

  mounted() {
    //一进入查询当前年份当前月份，列表默认打开第一个
    this.firstGetPlanList()
  }
}
