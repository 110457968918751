










import { Component, Vue } from 'vue-property-decorator'
import MemberReport from './components/MemberReport/index.vue'
import SummaryReport from './components/SummaryReport/index.vue'

@Component({
  components: {
    MemberReport,
    SummaryReport,
  },
})
export default class Report extends Vue {
  private reportType: 'member' | 'summary' = 'member'

  handleTypeChange(type: 'member' | 'summary') {
    this.reportType = type
  }
  mounted() {
    document.title = '数据统计'
  }
}
