import router from './router'
import { getToken } from '@/utils/auth'
import store from '@/store'
import { errorLog } from '@/utils/log'
import antd from 'ant-design-vue'
import Vue from 'vue'
import { redirectLoginFn } from '@/config/fetch/interceptor/auth'
const whiteList = [
  '/login',
  '/mobileDetail',
  '/download',
  '/privacy',
  '/userProtocol',
  '/childrenProtocol',
] // no redirect whitelist
//设置一个10分钟获取用户信息的定时器
let timer: any

const clearTimer = (time: any) => {
  clearInterval(time)
  timer = null
}

function redirectLogin(next: any) {
  clearTimer(timer)
  redirectLoginFn()
}

// 登录拦截
router.beforeEach(async (to, from, next) => {
  //清除上一个定时器
  clearInterval(timer)
  //设置每10分钟获取用户信息定时器
  timer = setInterval(() => {
    store.dispatch('user/getInfo')
  }, 600000)
  // 白名单放行
  if (whiteList.includes(to.path)) {
    clearTimer(timer)
    next()
    return
  }
  // determine whether the user has logged in
  const hasToken = getToken()
  if (to.query.token) {
    // url上带有token代表单点登录过来的
    const { token, apiToken, custId } = to.query
    console.log(token, apiToken, custId)
    store
      .dispatch('user/singleSign', { token, apiToken, custId })
      .then(async () => {
        if (to.query.planId) {
          const hasUserInfo = !!store.state.user.name
          if (!hasUserInfo) {
            await store
              .dispatch('user/getInfo')
              .then(next)
              .catch((e) => {
                next()
              })
          } else {
            next()
          }
          // next()
        } else {
          // 个人日志
          if (custId) {
            if (apiToken) {
              next(to.path)
            } else {
              redirectLogin(next)
            }
          }
          // 自然人日志
          else {
            next(to.path)
          }
        }
      })
      .catch(() => {
        clearTimer(timer)
        redirectLogin(next)
      })
    return
  } else if (hasToken) {
    // 登录状态下访问登录页面 跳转到首页
    if (to.path === '/login') {
      clearTimer(timer)
      next({ path: '/' })
      return
    }
  } else {
    redirectLogin(next)
    return
  }
  //刷新页面调用接口
  if (from.path === '/') {
    store.dispatch('user/getInfo')
  }
  // 获取用户信息
  const hasUserInfo = !!store.state.user.name
  if (!hasUserInfo) {
    await store
      .dispatch('user/getInfo')
      .then(next)
      .catch((e) => {
        next()
      })
  } else {
    next()
  }
})
