




























































































import { Component, Vue, Ref } from 'vue-property-decorator'
import MyTinymce from '@/components/Editor/index.vue'
import Share from '@/components/Share/index.vue'
import { createYearPlan, getYearPlanDetail } from '@/api/year'

@Component({
  components: {
    MyTinymce,
    Share,
  },
  async beforeRouteLeave(this: any, to: any, from: any, next: any) {
    console.log('beforeRouteLeave', this.formData)
    if (
      !this.formData.id ||
      (this.formData.id && this.formData.isSubmit == 0)
    ) {
      this.backLoading = true
      await this.stopAutoCheckAndAutoSave()
    }
    if (
      from.path === '/year/addYearPlan' &&
      to.path !== '/yearPlan/index' &&
      to.path !== '/year/showYearPlan'
    ) {
      if (this.isChange()) {
        this.$confirm({
          cancelText: '放弃',
          okText: '继续编辑',
          content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
          onCancel: () => {
            this.showPage = false
            this.$nextTick(() => {
              this.backLoading = false
              next()
            })
          },
          onOk: () => {
            this.backLoading = false
            next(false)
          },
        })
      } else {
        this.showPage = false
        this.backLoading = false
        this.$nextTick(() => {
          next()
        })
      }
    } else {
      this.showPage = false
      this.backLoading = false
      this.$nextTick(() => {
        next()
      })
    }
  },
})
export default class addYearPlan extends Vue {
  backLoading: boolean = false
  formData: any = {
    title: '',
    year: new Date().getFullYear(),
    yearContent: '',
    businessAttachmentList: [],
    shareList: [],
    isSubmit: 0,
  }
  id: any = ''
  oldForm: any = {
    title: '',
    year: '',
    yearContent: '',
    businessAttachmentList: [],
    isSubmit: 0,
  }
  currentIndex: number = new Date().getFullYear()
  currentYear: number = new Date().getFullYear()
  nextYear: number = new Date().getFullYear() + 1
  fileList: object[] = []
  year: any = ''
  private submitLoading = false
  private showPage = true
  mounted() {
    if (this.$route.query.year) {
      this.year = this.$route.query.year
      this.currentIndex = +this.$route.query.year
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.getPlanDetail(this.year)
      }
    } else if (this.$route.query.createYear) {
      this.year = this.$route.query.createYear
      this.currentIndex = +this.$route.query.createYear
      this.formData.year = +this.$route.query.createYear
    }
  }

  isChange() {
    // 只要一个有值改变并且没有点击提交，拦截
    return (
      (this.oldForm.title !== this.formData.title ||
        this.oldForm.yearContent !== this.formData.yearContent ||
        JSON.stringify(this.oldForm.businessAttachmentList) !==
          JSON.stringify(this.formData.businessAttachmentList)) &&
      !this.showAutoSave
    )
  }
  addShareList(e: any) {
    this.formData.shareList = e
  }

  async getPlanDetail(year: any) {
    const [err, data] = await getYearPlanDetail(year)
    if (data) {
      this.formData = data
      if (data.businessAttachmentList === null) {
        this.formData.businessAttachmentList = []
      }
      if (data.shareList === null) {
        this.formData.shareList = []
      }
      this.oldForm.title = this.formData.title
      this.oldForm.year = this.formData.year
      this.oldForm.yearContent = this.formData.yearContent
      this.oldForm.businessAttachmentList = this.formData.businessAttachmentList
    } else {
      this.formData = {
        title: '',
        year: '',
        yearContent: '',
        businessAttachmentList: [],
        isSubmit: 0,
      }
      this.oldForm.title = this.formData.title
      this.oldForm.year = this.formData.year
      this.oldForm.yearContent = this.formData.yearContent
      this.oldForm.businessAttachmentList = this.formData.businessAttachmentList
    }
  }
  async commitForm() {
    if (!this.formData.yearContent) {
      this.$message.warning('内容不能为空')
      return
    }
    this.submitLoading = true
    const [err, res] = await createYearPlan({
      ...this.formData,
      businessAttachmentList:
        this.formData.businessAttachmentList?.map((item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        })) ?? [],
      isSubmit: 1,
    })
    this.submitLoading = false
    if (!err) {
      this.formData.id = res
      this.formData.isSubmit = 1
      this.$message.success('提交成功')
      this.showPage = false
      this.$router.push({
        path: '/year/showYearPlan',
        query: { year: this.year },
      })
    }
  }
  async selectYear(e: number) {
    await this.stopAutoCheckAndAutoSave()
    var that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentIndex = e
          if (that.currentIndex === that.currentYear) {
            const year = new Date().getFullYear()
            await that.getPlanDetail(year)
            that.id = that.formData.id
            that.formData.year = new Date().getFullYear()
            that.year = new Date().getFullYear()
          } else {
            const year = new Date().getFullYear() + 1
            await that.getPlanDetail(year)
            that.id = that.formData.id
            that.formData.year = new Date().getFullYear() + 1
            that.year = new Date().getFullYear() + 1
          }
        },
      })
    } else {
      that.currentIndex = e
      if (that.currentIndex === that.currentYear) {
        const year = new Date().getFullYear()
        await that.getPlanDetail(year)
        that.id = that.formData.id
        that.formData.year = new Date().getFullYear()
        that.year = new Date().getFullYear()
      } else {
        const year = new Date().getFullYear() + 1
        await that.getPlanDetail(year)
        that.id = that.formData.id
        that.formData.year = new Date().getFullYear() + 1
        that.year = new Date().getFullYear() + 1
      }
    }
  }
  async cancel() {
    // this.$router.push('/yearPlan/index')
    this.$router.back()
  }

  @Ref('autoSaveRef')
  autoSaveRef: any

  get showAutoSave() {
    return (
      !this.formData.id || (this.formData.id && this.formData.isSubmit == 0)
    )
  }

  async handleAutoSave(stopTimer?: boolean) {
    console.log('handleAutoSave')
    const stop = typeof stopTimer === 'boolean' && stopTimer === true
    if (!this.showAutoSave) return
    if (!this.formData.yearContent) {
      if (!stop) {
        this.autoSaveRef?.setAutoCheck(false)
      }
      return
    }
    const [err, res] = await createYearPlan({
      ...this.formData,
      businessAttachmentList:
        this.formData.businessAttachmentList?.map((item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        })) ?? [],
      isSubmit: 0,
    })
    if (err) {
      if (!stop) {
        this.autoSaveRef?.setAutoCheck(false)
      }
      return
    }
    if (!this.formData.id) {
      this.formData.id = res
    }
    if (!stop) {
      this.autoSaveRef?.setAutoCheck()
    }
  }

  async stopAutoCheckAndAutoSave() {
    this.autoSaveRef?.stopAutoCheck(false)
    await this.handleAutoSave(true)
  }

  goBack() {
    this.$router.push('/yearPlan/index')
  }
}
