
















































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { searchPageList } from '@/api/dailyPlan'
// import moment from 'moment'
import { debounce } from 'lodash-es'

@Component
export default class SearchPlanList extends Vue {
  @Prop({ type: String, default: '' })
  keyword!: string

  @Ref('tableRef')
  tableRef: any

  @Watch('keyword')
  handleValueChange() {
    if (this.keyword) {
      this.queryParam = { workPlan: this.keyword }
      this.queryPlanList()
    }
  }

  private total = 0

  private columns = [
    {
      title: 'plan',
      scopedSlots: { customRender: 'planSlot' },
    },
  ]

  private queryParam: any = { workPlan: '' }

  setParams() {
    return {
      ...this.queryParam,
    }
  }

  async apiFun(params: any) {
    const [err, res] = await searchPageList(params)
    if (err) return [err, res]
    res.records = res.records.map((item: any) => {
      return {
        ...item,
        title: item.title.replaceAll(
          this.keyword,
          '<span style="color: #0176f6">' + this.keyword + '</span>',
        ),
        workPlan: item.workPlan.replaceAll(
          this.keyword,
          '<span style="color: #0176f6">' + this.keyword + '</span>',
        ),
      }
    })
    this.total = res.total
    return [err, res]
  }

  queryPlanList: any = () => {}

  customRow(record: any, index: string) {
    return {
      on: {
        click: () => {
          this.$emit('preivew', record)
        },
      },
    }
  }

  mounted() {
    this.queryParam = { workPlan: this.keyword }
    this.queryPlanList = debounce(() => {
      this.tableRef.refresh(true)
    }, 500)
    this.queryPlanList()
  }
}
