















































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import { getWeekPlanList, exportWeekPlan } from '@/api/week'
import moment from 'moment'
import WeekInfo from './components/weekInfo/index.vue'

const defaultParam = {
  time: [
    moment().subtract(30, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ],
}

@Component({
  components: {
    WeekInfo,
  },
})
export default class Week extends Vue {
  private moment: any = moment

  dateRanges: any = {
    近一周: [moment().subtract(7, 'days'), moment()],
    近一月: [moment().subtract(30, 'days'), moment()],
    近半年: [moment().subtract(6, 'months'), moment()],
    近一年: [moment().subtract(1, 'years'), moment()],
  }

  @Ref() readonly table!: any
  private columns = [
    {
      title: '日期',
      dataIndex: 'createTime',
      width: '200px',
      scopedSlots: { customRender: 'createTimeSlot' },
    },
    {
      title: '本周总结',
      dataIndex: 'thisWeekContent',
      scopedSlots: { customRender: 'thisWeekSlot' },
    },
    {
      title: '下周计划',
      dataIndex: 'nextWeekContent',
      scopedSlots: { customRender: 'nextWeekSlot' },
    },
    {
      title: '需要协调的资源或帮助',
      dataIndex: 'helpContent',
      scopedSlots: { customRender: 'helpContentSlot' },
    },
  ]
  private queryParam: any = { ...defaultParam }
  private apiFun = getWeekPlanList
  setParams() {
    const { time = [] } = this.queryParam
    return {
      dateScope:
        time[0].replaceAll('-', '/') + ' - ' + time[1].replaceAll('-', '/'),
    }
  }

  // 查询
  @Watch('queryParam.time')
  refreshTable(needRefreshTable: boolean = true) {
    this.table.refresh(needRefreshTable)
  }

  private addDara() {
    this.$router.push('/week/addWeeklyPlan')
  }
  // 详情
  detailModalVisible: boolean = false
  planInfo: any = {}
  customRow(record: any, index: string) {
    return {
      on: {
        click: () => {
          this.planInfo = record
          this.detailModalVisible = true
        },
      },
    }
  }

  exportLoading: boolean = false
  async handleExport() {
    this.exportLoading = true
    const { time = [] } = this.queryParam
    await exportWeekPlan({
      dateScope:
        time[0].replaceAll('-', '/') + ' - ' + time[1].replaceAll('-', '/'),
    })
    this.exportLoading = false
  }
}
