<template>
  <a-modal
    :title="title"
    width="800px"
    v-model="dialogVisible"
    destroyOnClose
    :mask="showModal"
    @cancel="dialogVisible = false"
  >
    <div class="dialog-wrapper">
      <NatureUser
        v-if="isNaturePersonVersion"
        ref="userListRef"
        :singleSelect="singleSelect"
        :currentUser="currentUser"
        :userList="userList"
        :disabledUserList="disabledUserList"
      />
      <OrgUser
        v-else
        ref="userListRef"
        :singleSelect="singleSelect"
        :currentUser="currentUser"
        :userList="userList"
        :disabledUserList="disabledUserList"
        :authTree="authTree"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <a-button @click="dialogVisible = false">取 消</a-button>
      <a-button type="primary" @click="handleConfirm">
        {{ confirmText }}
      </a-button>
    </span>
  </a-modal>
</template>

<script>
import OrgUser from './components/OrgUser.vue'
import NatureUser from './components/natureUser.vue'
export default {
  components: { OrgUser, NatureUser },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // 显示背景色
    showModal: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '选择人员',
    },
    // 确定按钮文案
    confirmText: {
      type: String,
      default: '确定',
    },
    // 单选
    singleSelect: {
      type: Boolean,
      default: false,
    },
    // 单选时回显的用户
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    // 多选时回显的用户列表
    userList: {
      type: Array,
      default: () => [],
    },
    // 禁止选择的用户，同时不可删除
    disabledUserList: {
      type: Array,
      default: () => [],
    },
    authTree: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submiting: false,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    isNaturePersonVersion() {
      return this.$store.getters['user/isNaturePersonVersion']
    },
  },
  methods: {
    // 选择用户
    handleConfirm() {
      this.submiting = true
      const selectedList = this.$refs.userListRef.getSelectedList()
      this.$emit('confirm', {
        currentUser: selectedList[selectedList.length - 1] ?? {},
        userList: selectedList,
        // 关闭弹框
        done: () => {
          this.submiting = false
          this.dialogVisible = false
        },
        // 取消关闭
        cancel: () => {
          this.submiting = false
          // this.dialogVisible = false
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.flex-between {
  display: flex;
  justify-content: space-between;
}

::v-deep .el-dialog__body {
  padding: 20px;
  padding-top: 0;
}

::v-deep .dialog-footer .el-button {
  width: auto;
}
</style>
