





















































import { Component, Vue, Ref } from 'vue-property-decorator'
import MyTinymce from '@/components/Editor/index.vue'
import Share from '@/components/Share/index.vue'
import { addWeekPlan } from '@/api/week'

@Component({
  components: {
    MyTinymce,
    Share,
  },
})
export default class WeekForm extends Vue {
  @Ref('autoSaveRef')
  autoSaveRef: any
  get showAutoSave() {
    return (
      !this.formData.id || (this.formData.id && this.formData.isSubmit == 0)
    )
  }

  private formData: any = {
    thisWeekContent: '',
    nextWeekContent: '',
    helpContent: '',
    businessAttachmentList: [],
    shareList: [],
    isSubmit: 0,
  }
  showShare: boolean = false
  currentPlanId: any = ''
  setFormData(data: any) {
    this.showShare = true
    if (data) {
      this.formData = { ...data }
    }
  }
  getFormData() {
    return this.formData
  }
  addShareList(e: any) {
    this.formData.shareList = e
  }
  async handleAutoSave() {
    console.log('handleAutoSave')
    const {
      thisWeekContent,
      nextWeekContent,
      helpContent,
      businessAttachmentList = [],
    } = this.formData
    if (!this.showAutoSave) {
      return
    }
    if (!thisWeekContent && !nextWeekContent && !helpContent) {
      this.autoSaveRef?.setAutoCheck(false)
      return
    }
    const [err, res] = await addWeekPlan({
      ...this.formData,
      businessAttachmentList: (businessAttachmentList || []).map(
        (item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        }),
      ),
      isSubmit: 0,
    })
    if (err) {
      return this.autoSaveRef?.setAutoCheck(false)
    }
    if (!this.formData.id) {
      this.formData.id = res
    }
    this.autoSaveRef?.setAutoCheck()
    this.$emit('submit')
  }
}
