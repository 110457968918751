






































































































































































































































































import {
  Component,
  Vue,
  Prop,
  ModelSync,
  Ref,
  Watch,
} from 'vue-property-decorator'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import { uuid } from '@/utils/util'
import Commit from '@/components/comment/index.vue'
import Share from '@/components/Share/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
import DatePicker from 'vue2-datepicker'
import MyTinymce from '@/components/Editor/index.vue'
import 'vue2-datepicker/index.css'

import {
  addDailyPlan,
  dailyPlanDetail,
  delPlan,
} from '@/api/dailyPlan'

const defaultForm = {
  title: '',
  time: [],
  workPlan: '',
  indicatorRecords: [
    {
      uid: Date.now(),
      indicatorType: undefined,
      indicatorDescription: '',
    },
  ],
  businessAttachmentList: [],
  shareList: [],
  isSubmit: 0,
}

@Component({
  components: { DatePicker, Commit, ThumbsUp, Share, MyTinymce },
})
export default class PlanInfoModal extends Vue {
  @ModelSync('value', 'input', { type: Boolean, default: false })
  modalVisible!: boolean

  @Prop({ type: Object, default: () => moment() }) currentDate!: any

  @Prop({ type: String, default: 'add' }) mode!: string

  @Prop({ type: Object, default: () => ({}) }) planInfo!: any

  @Ref('autoSaveRef')
  autoSaveRef: any

  moment = moment

  private submitLoading: boolean = false
  private innerMode: string = 'add'
  private tinymceMounted: boolean = false
  private panelOpend: boolean = false

  get isPreview() {
    return this.innerMode === 'preview'
  }

  get title() {
    return this.isPreview ? '查看' : this.innerMode === 'edit' ? '编辑' : '新建'
  }

  get currentDateStr() {
    const week = ['一', '二', '三', '四', '五', '六', '日']
    const date = this.formData.planDate
      ? moment(this.formData.planDate)
      : this.currentDate
    return date.format('YYYY年M月DD日  周') + week[date.isoWeekday() - 1]
  }

  get previewWorkPlan() {
    return this.formData.workPlan.replace(/\n/g, '<br/>')
  }

  get showAutoSave() {
    return (
      this.innerMode === 'add' ||
      (this.innerMode === 'edit' && this.formData.isSubmit == 0)
    )
  }

  toogleEdit() {
    this.formData.indicatorRecords =
      this.formData.indicatorRecords.length < 1
        ? cloneDeep(defaultForm.indicatorRecords)
        : this.formData.indicatorRecords
    this.innerMode = 'edit'
  }

  @Watch('value')
  handleValueChange(nval: boolean) {
    this.innerMode = this.mode
    if (nval) {
      this.getDetailById()
      this.$nextTick(() => {
        this.tinymceMounted = true
      })
    } else {
      this.tinymceMounted = false
      this.formData = { ...cloneDeep(defaultForm) }
    }
  }

  formData: any = { ...cloneDeep(defaultForm) }
  formRule = {
    workPlan: [
      { required: true, message: '请填写工作计划内容', trigger: 'blur' },
      // {
      // min: 1,
      // max: 500,
      // message: '工作计划内容应为1到500个字符',
      // trigger: 'blur',
      // },
    ],
  }

  handleAddIndex() {
    this.formData.indicatorRecords.push({
      uid: uuid(),
      indicatorType: undefined,
      indicatorDescription: '',
    })
  }
  addShareList(e: any) {
    this.formData.shareList = e
  }
  handleDeleteIndex(index: number) {
    this.formData.indicatorRecords.splice(index, 1)
  }

  private oldFormData = {}
  async getDetailById() {
    if (!this.planInfo.id) {
      this.formData = {
        ...cloneDeep(defaultForm),
        indicatorRecords: [
          {
            uid: Date.now(),
            indicatorType: undefined,
            indicatorDescription: '',
          },
        ],
        time: [
          moment().format('HH:mm'),
          moment().add(1, 'hours').format('HH:mm'),
        ],
        isSubmit: 0,
      }
      const formData = cloneDeep(this.formData)
      formData.indicatorRecords = []
      this.oldFormData = formData
      return
    }
    const [err, res] = await dailyPlanDetail(this.planInfo.id)
    if (err) return
    const {
      startTime,
      endTime,
      indicatorRecords = [],
      businessAttachmentList = [],
    } = res
    this.formData = {
      ...res,
      time: [startTime, endTime],
      businessAttachmentList,
    }
    if (this.innerMode === 'edit' && indicatorRecords.length < 1) {
      this.formData.indicatorRecords = [
        {
          uid: Date.now(),
          indicatorType: undefined,
          indicatorDescription: '',
        },
      ]
    }
    this.oldFormData = { ...cloneDeep(this.formData) }
  }

  @Ref('formRef')
  formRef: any
  async handleOk() {
    try {
      await this.formRef.validate()
      const { time, indicatorRecords, businessAttachmentList, ...data } =
        this.formData
      if (
        indicatorRecords.filter(
          (item: any) =>
            (item.indicatorType && !item.indicatorDescription) ||
            (!item.indicatorType && item.indicatorDescription),
        ).length > 0
      ) {
        return this.$message.warn('请完善指标类型说明')
      }
      this.submitLoading = true
      // 已发布的计划使用编辑接口，未发布或草稿状态使用添加接口
      // const isEdit = !!this.formData.id && this.formData.isSubmit == 1
      // const remoteMethod = isEdit ? editDailyPlan : addDailyPlan
      const planDate = data.planDate || this.currentDate.format('YYYY-MM-DD')
      const [err] = await addDailyPlan({
        ...data,
        startTime: planDate + ' ' + time[0],
        endTime: planDate + ' ' + time[1],
        indicatorRecords: indicatorRecords.filter(
          (item: any) => item.indicatorType,
        ),
        planDate,
        businessAttachmentList: businessAttachmentList.map((item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        })),
        isSubmit: 1,
      })
      this.submitLoading = false
      if (!err) {
        this.formData.isSubmit = 1
        this.$message.success(`提交成功`)
        this.$emit('refresh', !this.formData.id)
        this.modalVisible = false
      }
    } catch (e) {
      this.submitLoading = false
      console.error('onSubmit error', e)
    }
  }

  handleClose() {
    // if (this.isChange()) {
    //   this.$confirm({
    //     cancelText: '放弃',
    //     okText: '继续编辑',
    //     content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
    //     onOk: () => {
    //       this.modalVisible = false
    //     },
    //   })
    // } else {
    // 草稿状态 关闭时自动保存
    if (this.showAutoSave) {
      this.handleAutoSave()
      this.$emit('refreshDraft')
    }
    this.modalVisible = false
    // }
  }

  async handleAutoSave() {
    console.log('handleAutoSave')
    const { time, indicatorRecords, businessAttachmentList, ...data } =
      this.formData
    if (!this.formData.workPlan) {
      this.autoSaveRef?.setAutoCheck(false)
      return
    }
    const planDate = data.planDate || this.currentDate.format('YYYY-MM-DD')
    const [err, res] = await addDailyPlan({
      ...data,
      startTime: planDate + ' ' + time[0],
      endTime: planDate + ' ' + time[1],
      indicatorRecords: indicatorRecords.filter(
        (item: any) => item.indicatorType,
      ),
      planDate,
      businessAttachmentList: businessAttachmentList.map((item: any) => ({
        attachmentName: item.attachmentName,
        attachmentUrl: item.attachmentUrl,
      })),
      isSubmit: 0,
    })
    if (err) {
      return this.autoSaveRef?.setAutoCheck(false)
    }
    if (!this.formData.id) {
      this.formData.id = res
      this.innerMode = 'edit'
    }
    this.autoSaveRef?.setAutoCheck()
  }

  handleDelete() {
    this.$confirm({
      content: '确定要删除当前计划?',
      onOk: async () => {
        const [err] = await delPlan(this.formData.id)
        if (!err) {
          this.autoSaveRef?.stopAutoCheck()
          this.$message.success('删除成功!')
          this.modalVisible = false
          this.$emit('refreshDraft')
        }
      },
    })
  }

  isChange() {
    const newFormData = { ...cloneDeep(this.formData) }
    newFormData.indicatorRecords = newFormData.indicatorRecords.filter(
      (item: any) => item.indicatorType || item.indicatorDescription,
    )
    const newFormStr = JSON.stringify(newFormData)
    const oldFormStr = JSON.stringify(this.oldFormData)
    console.log(this.oldFormData, newFormData, oldFormStr === newFormStr)
    return newFormStr !== oldFormStr
  }
}
