















































import { Component, Vue, Prop } from 'vue-property-decorator'
import Commit from '@/components/comment/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
import { getWeekPlanInfo } from '@/api/week'

const defaultForm = {}

@Component({
  components: { Commit, ThumbsUp },
})
export default class WeekDetail extends Vue {
  formData: any = { ...defaultForm }
  @Prop({ type: String, default: '', required: true })
  planId!: string
  dataLoading: boolean = true
  async getDetailById(id: string) {
    const [err, res] = await getWeekPlanInfo(id)
    if (err) {
      this.dataLoading = false
      return
    }
    this.formData = res
    this.dataLoading = false
  }
  mounted() {
    this.getDetailById(this.planId)
  }
}
