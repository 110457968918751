// @ts-nocheck
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import {
  getMonthPlanList,
  addMonthPlan,
  getMonthPlanDetail,
  exportMonthPlan,
  getMonthPlanStatus,
  delPlan,
} from '@/api/month'
import moment from 'moment'
import { uuid } from '@/utils/util'

moment.locale('zh-cn', {
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY-MM-DD',
    LL: 'YYYY年MM月DD日',
    LLL: 'YYYY年MM月DD日Ah点mm分',
    LLLL: 'YYYY年MM月DD日ddddAh点mm分',
    l: 'YYYY-M-D',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日dddd HH:mm',
  },
})

@Component({
  components: {},
})
export default class MonthMixin extends Vue {
  //判断有没有编辑
  isChange() {
    // 只要一个有值改变并且没有点击提交，拦截
    return (
      (this.oldForm.title !== this.formData.title ||
        this.oldForm.content !== this.formData.content ||
        JSON.stringify(this.oldForm.businessAttachmentList) !==
          JSON.stringify(this.formData.businessAttachmentList)) &&
      this.currentStatus === 'edit' &&
      !this.showAutoSave
    )
  }

  //当首次进入 和 点击月份切换时操作
  //获取月份计划状态、获取月计划列表
  protected async firstGetPlanList(resetCurrentPlan: boolean = true) {
    await this.getMonthStatus(this.thisYear)
    await this.getMonthPlanListByMonth(
      this.thisYear,
      this.currentMonth,
      resetCurrentPlan,
    )
    console.log('firstGetPlanList', this.planData)
    if (this.planData && this.planData.length > 0) {
      const planId = this.planData[0].id ?? ''
      this.currentPlanId = planId
      this.currentPlanIndex = 0
      if (planId) {
        this.currentStatus = 'done'
        await this.getMonthPlanDetail(this.planData[0].id)
      } else {
        this.currentStatus = 'edit'
        this.formData = {
          uuid: uuid(),
          title: '',
          content: '',
          planYear: this.thisYear,
          planMonth: this.currentMonth,
          businessAttachmentList: [],
          shareList: [],
          isSubmit: 0,
        }
        this.oldForm.title = this.formData.title
        this.oldForm.content = this.formData.content
        this.oldForm.businessAttachmentList =
          this.formData.businessAttachmentList
      }
    } else {
      this.createMonthPlan()
    }
  }

  // 年份切换
  protected async changeYear(value: any) {
    const that = this
    await this.stopAutoCheckAndAutoSave()
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentStatus = 'done'
          that.yearShowOne = false
          that.dataYear = moment(value).format('YYYY')
          that.$nextTick(async () => {
            //默认查询选择年份从1月份开始
            await that.getMonthPlanListByMonth(that.dataYear, '1', true)
            that.thisYear = that.dataYear
            that.currentMonth = '1'
            that.firstGetPlanList()
          })
        },
      })
    } else {
      this.currentStatus = 'done'
      this.yearShowOne = false
      this.dataYear = moment(value).format('YYYY')
      this.$nextTick(async () => {
        //默认查询选择年份从1月份开始
        await this.getMonthPlanListByMonth(this.dataYear, '1', true)
        this.thisYear = this.dataYear
        this.currentMonth = '1'
        this.firstGetPlanList()
      })
    }
  }

  //点击今年
  protected async selectCurrentYearList() {
    const that = this
    await this.stopAutoCheckAndAutoSave()
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.thisYear = new Date().getFullYear().toString()
          that.dataYear = that.thisYear
          that.firstGetPlanList()
          that.currentStatus = 'done'
        },
      })
    } else {
      this.thisYear = new Date().getFullYear().toString()
      that.dataYear = that.thisYear
      this.firstGetPlanList()
      this.currentStatus = 'done'
    }
  }

  //获取当前年份下的月份计划状态
  monthList: any = [
    {
      planMonth: '1',
      planCount: '12',
    },
    {
      planMonth: '2',
      planCount: '12',
    },
    {
      planMonth: '3',
      planCount: '0',
    },
    {
      planMonth: '4',
      planCount: '0',
    },
    {
      planMonth: '5',
      planCount: '12',
    },
    {
      planMonth: '6',
      planCount: '12',
    },
    {
      planMonth: '7',
      planCount: '0',
    },
    {
      planMonth: '8',
      planCount: '12',
    },
    {
      planMonth: '9',
      planCount: '0',
    },
    {
      planMonth: '10',
      planCount: '0',
    },
    {
      planMonth: '11',
      planCount: '0',
    },
    {
      planMonth: '12',
      planCount: '0',
    },
  ]
  protected async getMonthStatus(planYear: string) {
    const [err, data] = await getMonthPlanStatus({ planYear })
    if (err) return
    this.monthList = data
  }

  // 当月份切换时
  protected async changeMonth(type: string) {
    await this.stopAutoCheckAndAutoSave()
    const that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentMonth = type
          that.formData.planMonth = type
          that.firstGetPlanList()
        },
      })
    } else {
      this.currentMonth = type
      this.formData.planMonth = type
      this.firstGetPlanList()
    }
  }

  // 当计划切换时
  protected async changhePlanItem(index: number, id: string) {
    console.log('changhePlanItem', index, this.currentPlanIndex)
    if (index == this.currentPlanIndex) return
    await this.stopAutoCheckAndAutoSave()
    await this.getMonthPlanListByMonth(this.thisYear, this.currentMonth, false)
    const that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentStatus = 'done'
          that.currentPlanIndex = index
          that.currentPlanId = id
          if (that.currentPlanId) {
            that.getMonthPlanDetail(that.currentPlanId)
          } else {
            that.formData = {
              uuid: uuid(),
              title: '',
              content: '',
              planYear: that.thisYear,
              planMonth: that.currentMonth,
              businessAttachmentList: [],
              shareList: [],
              isSubmit: 0,
            }
            that.oldForm.title = that.formData.title
            that.oldForm.content = that.formData.content
            that.oldForm.businessAttachmentList =
              that.formData.businessAttachmentList
          }
        },
      })
    } else {
      this.currentStatus = 'done'
      this.currentPlanIndex = index
      this.currentPlanId = id
      if (this.currentPlanId) {
        this.getMonthPlanDetail(this.currentPlanId)
      } else {
        this.formData = {
          uuid: uuid(),
          title: '',
          content: '',
          planYear: this.thisYear,
          planMonth: this.currentMonth,
          businessAttachmentList: [],
          shareList: [],
          isSubmit: 0,
        }
        this.oldForm.title = this.formData.title
        this.oldForm.content = this.formData.content
        this.oldForm.businessAttachmentList =
          this.formData.businessAttachmentList
      }
    }
  }

  //获取月计划列表
  protected async getMonthPlanListByMonth(
    planYear: any,
    planMonth: any,
    resetCurrentPlan: boolean,
  ) {
    const [err, data] = await getMonthPlanList({ planYear, planMonth })
    const resp = data || []
    if (!err) {
      if (resetCurrentPlan) {
        this.planData = resp
      } else {
        this.planData = this.planData
          .filter((item) => {
            if (item.id) {
              return (
                resp.findIndex((dataItem) => dataItem.id === item.id) !== -1
              )
            } else {
              return true
            }
          })
          .map((item) => {
            const remoteData = data.find(
              (retemoData) => retemoData.id === item.id,
            )
            return remoteData || item
          })
      }
      if (this.planData && this.planData.length > 0 && resetCurrentPlan) {
        this.currentPlanIndex = 0
        this.currentPlanId = this.planData[0].id
      }
    }
  }

  //新建计划
  protected async createMonthPlan(from?: any) {
    console.log('createMonthPlan', from, this.formData.content)
    if (from === 'btn') {
      await this.stopAutoCheckAndAutoSave()
      // 当前表单有值时，重新获取一下计划列表
      if (this.formData.content) {
        await this.getMonthPlanListByMonth(
          this.thisYear,
          this.currentMonth,
          false,
        )
      }
    }
    this.planData.unshift({
      uuid: uuid(),
      id: '',
      title: '新建计划',
      updateTime: '',
      isSubmit: 0,
      key: uuid(),
    })
    this.currentStatus = 'edit'
    this.currentPlanIndex = 0
    this.currentPlanId = ''
    this.formData = {
      uuid: uuid(),
      title: '',
      content: '',
      planYear: this.thisYear,
      planMonth: this.currentMonth,
      businessAttachmentList: [],
      shareList: [],
      isSubmit: 0,
    }
    this.oldForm.title = this.formData.title
    this.oldForm.content = this.formData.content
    this.oldForm.businessAttachmentList = this.formData.businessAttachmentList
  }

  //编辑计划
  protected editPlan(index: number, id: string) {
    //表单状态设置为编辑
    this.currentStatus = 'edit'
    const that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentPlanIndex = index
          that.currentPlanId = id
          if (that.currentPlanId) {
            that.getMonthPlanDetail(that.currentPlanId)
          } else {
            that.formData = {
              uuid: uuid(),
              title: '',
              content: '',
              planYear: that.thisYear,
              planMonth: that.currentMonth,
              businessAttachmentList: [],
              shareList: [],
              isSubmit: 0,
            }
            that.oldForm.title = that.formData.title
            that.oldForm.content = that.formData.content
            that.oldForm.businessAttachmentList =
              that.formData.businessAttachmentList
          }
        },
      })
    } else {
      this.currentPlanIndex = index
      this.currentPlanId = id
      if (this.currentPlanId) {
        this.getMonthPlanDetail(this.currentPlanId)
      } else {
        this.formData = {
          uuid: uuid(),
          title: '',
          content: '',
          planYear: this.thisYear,
          planMonth: this.currentMonth,
          businessAttachmentList: [],
          shareList: [],
          isSubmit: 0,
        }
        this.oldForm.title = this.formData.title
        this.oldForm.content = this.formData.content
        this.oldForm.businessAttachmentList =
          this.formData.businessAttachmentList
      }
    }
  }

  //查询计划详情
  protected async getMonthPlanDetail(id: string) {
    const [err, data] = await getMonthPlanDetail(id)
    if (!err) {
      this.formData = data
      this.formData.uuid = uuid()
      if (data.businessAttachmentList === null) {
        this.formData.businessAttachmentList = []
      }
      this.oldForm.title = this.formData.title
      this.oldForm.content = this.formData.content
      this.oldForm.businessAttachmentList = this.formData.businessAttachmentList
    }
  }

  //提交月计划
  protected async submitMonthPlan() {
    if (!this.formData.content) {
      return this.$message.error('请输入计划内容')
    }
    this.submitLoading = true
    const [err, data] = await addMonthPlan({
      ...this.formData,
      businessAttachmentList:
        this.formData.businessAttachmentList?.map((item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        })) ?? [],
      isSubmit: 1,
    })
    if (!err) {
      this.currentPlanId = data
      this.formData.id = data
      this.formData.isSubmit = 1
      this.$set(this.planData, this.currentPlanIndex, {
        ...this.formData,
        id: data,
      })
      this.$message.success('提交成功')
      this.submitLoading = false
      this.currentStatus = 'done'
      //修改或者添加成功重新获取计划列表
      await this.getMonthPlanListByMonth(
        this.thisYear,
        this.currentMonth,
        false,
      )
      this.getMonthPlanDetail(this.currentPlanId)
    } else {
      this.submitLoading = false
    }
  }

  // 提交草稿状态计划
  protected async submitDraftMonthData(plan: any) {
    if (!plan.content) {
      return this.$message.error('请输入计划内容')
    }
    const [err, data] = await addMonthPlan({
      ...plan,
      businessAttachmentList:
        plan.businessAttachmentList?.map((item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        })) ?? [],
      isSubmit: 1,
    })
    if (err) return
    this.$message.success('提交成功')
    if (this.currentPlanId === plan.id) {
      this.currentStatus = 'done'
    }
    if (this.formData.id === plan.id) {
      this.formData.isSubmit = 1
    }
    this.getMonthPlanListByMonth(this.thisYear, this.currentMonth, false)
  }
  // 删除草稿状态计划
  protected async delDraftMonthData(plan: any, index: number) {
    console.log('delDraftMonthData', plan)
    if (!plan.id) {
      this.planData.splice(index, 1)
      if (this.planData.length == 0) {
        this.createMonthPlan()
      }
      return
    }
    const [err, data] = await delPlan(plan.id)
    if (err) return
    if (this.currentPlanId === plan.id) {
      this.currentStatus = 'done'
      this.autoSaveRef?.stopAutoCheck()
      this.autoSaveRef?.setAutoCheck()
    }
    this.firstGetPlanList(false)
  }
}
