




















import { Component, Vue, Ref } from 'vue-property-decorator'
import WeekForm from '../components/weekForm/index.vue'
import { addWeekPlan, delPlan } from '@/api/week'

@Component({
  components: {
    WeekForm,
  },
  beforeRouteLeave(this: any, to: any, from: any, next: any) {
    if (!this.submitted) {
      this.$refs.weekFormRef?.handleAutoSave()
    }
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onCancel: () => {
          this.showPage = false
          this.$nextTick(() => {
            next()
          })
        },
        onOk: () => {
          next(false)
        },
      })
    } else {
      this.showPage = false
      this.$nextTick(() => {
        next()
      })
    }
  },
})
export default class Week extends Vue {
  @Ref('weekFormRef')
  private weekFormRef!: any
  private submitLoading = false
  formData: any = {}

  get showDel() {
    return this.formData.id && this.formData.isSubmit == 0
  }

  isChange() {
    const formData = this.weekFormRef.getFormData()
    // formData没值，放行
    if (!formData) return false
    const { thisWeekContent, nextWeekContent, helpContent, isSubmit } = formData
    // 只要一个有值并且没有点击提交，拦截
    return (
      (thisWeekContent || nextWeekContent || helpContent) &&
      !this.submitted &&
      isSubmit != 0
    )
  }
  private showPage = true
  cancel() {
    this.$router.push('/weekPlan/index')
  }
  private submitted: boolean = false
  async submitData() {
    const formData = this.weekFormRef.getFormData()
    if (!formData) return
    const {
      thisWeekContent,
      nextWeekContent,
      helpContent,
      businessAttachmentList = [],
    } = formData
    if (!thisWeekContent && !nextWeekContent && !helpContent) {
      return this.$message.error('请填写计划内容')
    }
    this.submitLoading = true
    const [err, res] = await addWeekPlan({
      ...formData,
      businessAttachmentList: (businessAttachmentList || []).map(
        (item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        }),
      ),
      isSubmit: 1,
    })
    this.submitLoading = false
    if (err) return
    this.formData.id = res
    this.formData.isSubmit = 1
    this.submitted = true
    this.$message.success('添加成功')
    this.cancel()
  }
  handleAutoSubmit() {
    this.submitted = true
    this.formData = this.weekFormRef.getFormData()
  }
  handleDel() {
    this.$confirm({
      content: '确定要删除当前计划?',
      onOk: async () => {
        const [err] = await delPlan(this.formData.id)
        if (!err) {
          this.formData.id = ''
          this.$message.success('删除成功!')
          this.cancel()
        }
      },
    })
  }
  mounted() {
    this.weekFormRef.setFormData()
  }
}
