

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class DraftPlanList extends Vue {
  @Prop({ type: Boolean, default: false })
  loading!: boolean
  @Prop({ type: Array, default: () => [] })
  planList!: any[]

  moment = moment
  
  handlePreview(record: any) {
    this.$emit('preview', record)
  }
}
