



























































import { Component, Vue, Prop, ModelSync, Watch } from 'vue-property-decorator'
import Commit from '@/components/comment/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
import { getWeekPlanInfo } from '@/api/week'
import Share from '@/components/Share/index.vue'
const defaultForm = {}

@Component({
  components: { Commit, ThumbsUp, Share },
})
export default class WeekInfo extends Vue {
  @ModelSync('value', 'input', { type: Boolean, default: false })
  modalVisible!: boolean

  @Prop({ type: Object, default: () => ({}) }) planInfo!: any

  @Watch('value')
  handleValueChange(nval: boolean) {
    if (nval) {
      this.getDetailById()
    } else {
      this.formData = { ...defaultForm }
    }
  }

  formData: any = { ...defaultForm }
  get getRole() {
    return this.$store.state.user.userInfo.userType
  }
  get getApiToken() {
    return this.$store.state.user.userInfo.apiToken
  }
  async getDetailById() {
    const [err, res] = await getWeekPlanInfo(this.planInfo.id)
    if (err) return
    this.formData = res
  }

  handleOk() {
    this.$router.push('/week/editWeeklyPlan/' + this.planInfo.id)
  }

  handleClose() {
    this.modalVisible = false
  }
}
