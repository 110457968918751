import ls from '@/config/localstorage'
import {
  setToken,
  removeToken,
  getToken,
  setApiToken,
  getApiToken,
  removeApiToken,
} from '@/utils/auth'
import { login, loginCode, logout, getUserInfo, singleSign } from '@/api/common'
import { resetRouter } from '@/router'
import { errorLog } from '@/utils/log'
import { SUPER_ROLE_ID } from '@/config/constant'

export interface UserState {
  token: string
  apiToken: string
  name: string
  userInfo: any
  custId: string
}

interface UserMutaions {
  RESET_STATE(state: UserState): void
  SET_TOKEN(state: UserState, token: string): void
  SET_API_TOKEN(state: UserState, token: string): void
  SET_NAME(state: UserState, name: string): void
  SET_USERINFO(state: UserState, user: any): void
}

type AugmentedActionContext = {
  commit<K extends keyof UserMutaions>(
    key: K,
    payload?: Parameters<UserMutaions[K]>[1],
  ): ReturnType<UserMutaions[K]>
  dispatch: any
  state: UserState
}

interface UserActions {
  login(
    { commit }: AugmentedActionContext,
    userInfo: { userName: string; password: string },
  ): void
  singleSign(
    { commit }: AugmentedActionContext,
    tokenInfo: { token: string; apiToken: string; custId: string },
  ): void
  loginCode(
    { commit }: AugmentedActionContext,
    userInfo: { mobile: string; smsCode: string },
  ): void
  getInfo({ commit }: AugmentedActionContext): void
  logout({ commit, state }: AugmentedActionContext): void
  setToken({ commit }: AugmentedActionContext, token: string): void
  resetToken({ commit }: AugmentedActionContext): void
  setUserInfo({ commit }: AugmentedActionContext, userInfo: any): void
}
const userNameKey = 'DAILY_LOG_USER_NAME'
import { USER_INFO_KEY } from '@/config/constant'
const getDefaultState = () => {
  return {
    token: getToken(),
    apiToken: getApiToken(),
    name: ls.get(userNameKey, ''),
    userInfo: ls.get(USER_INFO_KEY, {}),
  } as UserState
}

const state = getDefaultState()

const mutations: UserMutaions = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token: string) => {
    state.token = token
  },
  SET_API_TOKEN: (state, token: string) => {
    state.apiToken = token
  },
  SET_NAME: (state, name: string) => {
    state.name = name
  },
  SET_USERINFO: (state, userInfo: any) => {
    state.userInfo = userInfo
  },
}

const actions: UserActions = {
  // user login
  async login({ commit }, userInfo) {
    const { userName, password } = userInfo
    const [err, data] = await login({
      userCode: userName.trim(),
      password: password,
    })
    if (err) {
      return Promise.reject(err)
    }
    console.log(data)
    const token = data
    console.log(token)
    commit('SET_TOKEN', token)
    setToken(token)
    return Promise.resolve()
  },
  async loginCode({ commit }, userInfo) {
    const { mobile, smsCode } = userInfo
    const [err, data] = await loginCode({
      mobile: mobile,
      smsCode: smsCode,
    })
    if (err) {
      return Promise.reject(err)
    }
    const token = data
    commit('SET_TOKEN', token)
    setToken(token)
    return Promise.resolve()
  },
  async singleSign({ commit }, { token, apiToken = '', custId = '' }) {
    const [err, data] = await singleSign(token, apiToken, custId)
    if (err || !data) {
      return Promise.reject(err || data)
    }
    ls.set(userNameKey, '')
    ls.set('user_cust_id', custId)
    commit('SET_NAME', '')
    commit('SET_TOKEN', data)
    commit('SET_API_TOKEN', apiToken)
    setToken(data)
    setApiToken(apiToken)
    return Promise.resolve()
  },
  // get user info
  async getInfo({ commit }) {
    const [err, data] = await getUserInfo()
    console.log(data)
    if (err) {
      errorLog('getUserInfo error', err)
      return Promise.reject(err)
    }
    const { userName } = data
    commit('SET_NAME', userName)
    commit('SET_USERINFO', data)
    ls.set(userNameKey, userName)
    ls.set(USER_INFO_KEY, data)
    return Promise.resolve(data)
  },

  // user logout
  async logout({ commit, dispatch }) {
    const [err] = await logout()
    if (err) {
      errorLog('logout error', err)
      return Promise.reject(err)
    }
    removeToken() // must remove  token  first
    removeApiToken()
    resetRouter()
    dispatch('permission/resetRoutes', {}, { root: true })
    ls.remove(userNameKey)
    ls.remove(USER_INFO_KEY)
    commit('RESET_STATE')
    return Promise.resolve()
  },

  setToken({ commit }, token: string) {
    commit('SET_TOKEN', token)
    setToken(token)
  },
  // remove token
  resetToken({ commit, dispatch }) {
    removeToken() // must remove  token  first
    removeApiToken()
    resetRouter()
    dispatch('permission/resetRoutes', {}, { root: true })
    ls.remove(userNameKey)
    ls.remove(USER_INFO_KEY)
    commit('RESET_STATE')
  },

  setUserInfo({ commit }, userInfo) {
    commit('SET_USERINFO', userInfo)
  },
}

const getters = {
  isSuperAdmin: (state: UserState) => {
    const roleIds = state.userInfo.roleIds ?? []
    // .join(
    //   '0ef89403f4d111eb9d7f0242ac110004'
    // )
    return roleIds.includes(SUPER_ROLE_ID)
  },
  isNaturePersonVersion: (state: UserState) => state.userInfo.userType == 1,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
