<template>
  <div class="auto-save-wrapper" v-if="lastTime">
    <img
      v-if="!loading"
      style="position: relative; top: -2px"
      src="@/assets/img/icon_saved.svg"
    />
    <a-icon type="loading" v-if="loading" />
    <span style="margin-left: 5px">最后保存时间:</span>
    <span>{{ lastTime }}</span>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    id: { type: String, default: '' },
    checkChange: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      timer: null,
      countdown: 20,
      lastTime: '',
      loading: false,
    }
  },
  methods: {
    autoCheck() {
      if (this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        const isChange = this.checkChange()
        console.warn(`autoCheck countdown::${this.timer}:: `, isChange)
        if (isChange) {
          this.loading = true
          this.$emit('save')
          clearInterval(this.timer)
        }
      }, 1000 * this.countdown)
      console.warn(`autoCheck start::${this.timer}::`)
    },
    setAutoCheck(syncTime = true) {
      console.warn(`setAutoCheck ::${this.timer}:: `, syncTime)
      if (syncTime) {
        this.lastTime = moment().format('HH:mm:ss')
      }
      this.loading = false
      this.autoCheck()
    },
    stopAutoCheck() {
      console.warn(`stopAutoCheck::${this.timer}::`)
      this.lastTime = ''
      this.loading = false
      clearInterval(this.timer)
    },
  },
  mounted() {
    clearInterval(this.timer)
    this.autoCheck()
  },
  destroyed() {
    console.warn(`autoCheck destroyed::${this.timer}::`)
    clearInterval(this.timer)
  },
}
</script>

<style lang="less" scoped>
.auto-save-wrapper {
  font-size: 14px;
  color: #666666;
}
</style>
