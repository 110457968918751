<template>
  <div class="user-select-wrapper">
    <div class="responsiblePerson">
      <div class="tag-wrapper">
        <a-tag
          class="tag"
          size="small"
          v-for="user in selectedList"
          :key="user.id"
          :closable="
            disabledUserList.findIndex((item) => item.id === user.id) < 0
          "
          @close="handleRemove(user)"
        >
          {{ user.name || user.realName }}
        </a-tag>
      </div>
      <img
        v-if="selectedList.length > 0"
        class="close-icon"
        src="@/assets/address_book_img/icon_celect_delete.svg"
        @click="handleEmpty"
      />
    </div>
    <div class="user-select-content">
      <div class="right">
        <a-input
          placeholder="输入姓名搜索"
          v-model="filterText"
          @input="handleFilterChange"
          allowClear
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <div style="margin-top: 10px" v-show="currentList.length > 1">
          <a-checkbox v-model="checkAll"> 全选 </a-checkbox>
        </div>
        <div class="user-list">
          <template v-if="currentList.length > 0">
            <div class="user-item" v-for="ele in currentList" :key="ele.id">
              <div class="user-row">
                <a-checkbox
                  style="margin-right: 8px"
                  :disabled="
                    disabledUserList.findIndex((item) => item.id === ele.id) >
                      -1
                  "
                  :checked="
                    selectedList
                      .map((item) => item.id)
                      .join(',')
                      .includes(ele.id)
                  "
                  @change="(val) => handleSelect(ele, val)"
                >
                  <div class="flex-between">
                    <a-avatar
                      class="avatar"
                      :size="24"
                      :src="ele.headSculpture"
                    >
                      <img
                        style="width: 25px; height: 25px"
                        src="@/assets/img/头像 拷贝.png"
                      />
                    </a-avatar>
                    <span class="user-name" :title="ele.name">
                      {{ ele.name }}
                    </span>
                  </div>
                </a-checkbox>
              </div>
            </div>
          </template>
          <a-empty v-else style="margin-left: 220px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { intersectionBy, unionBy, xorBy, cloneDeep } from 'lodash-es'
import { natureFriendList } from '@/api/common'
export default {
  components: {},
  props: {
    // 单选
    singleSelect: {
      type: Boolean,
      default: false,
    },
    // 单选时回显的用户
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    // 多选时回显的用户列表
    userList: {
      type: Array,
      default: () => [],
    },
    // 禁止选择的用户，同时不可删除
    disabledUserList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterText: '',
      submiting: false,
      currentList: [],
      allUserList: [],
      selectedList: [],
    }
  },
  computed: {
    checkAll: {
      get() {
        const intersection = intersectionBy(
          this.currentList,
          this.selectedList,
          'id',
        )
        return intersection.length === this.currentList.length
      },
      set(flag) {
        if (flag) {
          this.selectedList = unionBy(
            [
              ...this.selectedList,
              ...this.currentList,
              ...this.disabledUserList,
            ],
            'id',
          )
        } else {
          const orList = xorBy(this.selectedList, this.currentList, 'id')
          this.selectedList = unionBy(
            [...orList, ...this.disabledUserList],
            'id',
          )
        }
      },
    },
  },
  methods: {
    // 搜索人员或组织
    handleFilterChange() {
      if (this.filterText === '') {
        this.currentList = [...this.allUserList]
        return
      }
      this.currentList = this.allUserList.filter((item) =>
        item.name.includes(this.filterText),
      )
    },
    // 选择人员
    handleSelect(data, checked) {
      const index = this.selectedList.findIndex((item) => item.id === data.id)
      if (checked && index < 0) {
        if (this.singleSelect) {
          this.selectedList = [{ ...data }]
        } else {
          this.selectedList.push({
            ...data,
          })
        }
      } else {
        if (index > -1) {
          this.selectedList.splice(index, 1)
        }
      }
    },
    // 删除人员
    handleRemove(data) {
      const index = this.selectedList.findIndex((item) => item.id === data.id)
      if (index > -1) {
        this.selectedList.splice(index, 1)
      }
    },
    handleEmpty() {
      this.selectedList = []
      this.selectedList = this.disabledUserList
        .map((item) => {
          return this.allUserList.find((u) => u.id === item.id)
        })
        .filter((item) => !!item)
    },
    // 回显
    setCheckedNodes() {
      if (this.singleSelect) {
        if (this.currentUser.id) {
          const user = this.allUserList.find(
            (item) => item.id === this.currentUser.id,
          )
          if (user) {
            this.selectedList = [{ ...user }]
          }
        } else {
          this.selectedList = []
        }
      } else {
        this.selectedList = this.userList
          .map((item) => {
            return this.allUserList.find((u) => u.id === item.id)
          })
          .filter((item) => !!item)
      }
    },
    // 查询好友列表
    async getUserList() {
      const [err, res] = await natureFriendList()
      if (err) return
      this.allUserList = res.map(({ headSculpture, friendName, friendId }) => ({
        name: friendName,
        id: friendId,
        headSculpture,
      }))
      this.currentList = [...this.allUserList]
      this.setCheckedNodes()
    },
    getSelectedList() {
      return [...this.selectedList]
    },
  },
  mounted() {
    this.getUserList()
    this.$nextTick(() => {
      this.filterText = ''
      this.selectedList = []
    })
  },
}
</script>

<style lang="less" scoped>
.flex-between {
  display: flex;
  justify-content: space-between;
}

.user-select-wrapper {
  & ::v-deep {
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      border-radius: 0;
      border-color: #f2f2f2;
      .el-input__icon {
        line-height: 32px !important;
      }
    }
    .el-select .el-input__inner {
      padding-left: 10px;
      padding-right: 10px;
    }
    .el-select .el-input {
      background: transparent;
    }
  }
}

.user-select-content {
  border: 1px solid #f2f2f2;
  min-height: 300px;
  .right {
    padding: 15px 15px 0 15px;
    align-items: center;
    .user-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      padding-top: 10px;
      height: 410px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
      ::v-deep .ant-avatar-string {
        transform: scale(1.1) !important;
        left: -2px !important;
        top: -2px !important;
      }
    }
  }
  .user-item {
    width: 25%;
    flex: 0 0 25%;
    margin: 4px 0;
    ::v-deep {
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
      }
      .ant-checkbox {
        display: flex;
        align-items: center;
      }
    }
    .user-name {
      margin-left: 8px;
      cursor: pointer;
      width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.responsiblePerson {
  min-height: 40px;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 10px 0;
  border: 1px solid rgba(220, 223, 230, 1);
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
  }
  .tag-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 150px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  .tag {
    margin-bottom: 10px;
  }
  .close-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -5px;
    cursor: pointer;
  }
}
</style>
