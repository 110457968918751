












































import { Component, Vue } from 'vue-property-decorator'
import GlobalMenu from '@/components/GlobalMenu'
import PageView from './PageView.vue'
import defaultLogo from '@/assets/logo.png'
import { Meta } from 'ant-design-vue/types/meta'
import PostChoose from '@/components/postChoose/index.vue'
import { setTingBtnShow, setTingMenu } from '@/api/statistics'
@Component({
  components: {
    GlobalMenu,
    PageView,
    PostChoose,
  },
})
export default class extends Vue {
  private personVisible: boolean = false
  private showsetting() {
    // debugger
    this.personVisible = true
  }
  mounted() {
    // this.getIfSettingBtnShow()
    // this.getIfSettingMenuShow()
  }
  private btnStatuts: boolean = false
  private menuSatuts: boolean = false
  async getIfSettingBtnShow() {
    const [err, res] = await setTingBtnShow()
    if (!err) {
      this.btnStatuts = res
    }
  }
  async getIfSettingMenuShow() {
    const [err, res] = await setTingMenu()
    if (!err) {
      this.menuSatuts = res
    }
  }
  private personCancel(type: boolean) {
    this.personVisible = type
  }
  get breadcrumbNames() {
    return this.$store.getters['breadcrumb/breadcrumbNames']
  }

  get currentPath() {
    return this.$route.fullPath
  }
}
