import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import ls from '@/config/localstorage'
import 'ant-design-vue/dist/antd.css'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { getQueryString } from '@/utils/index'

// 添加认证信息
export const addAuth = () => {
  return (config: AxiosRequestConfig) => {
    const Authorization = getToken()
    if (Authorization) {
      config.headers = Object.assign({}, config.headers, {
        Authorization,
      })
    }
    return config
  }
}

// 登录拦截
export let redirectLoginState = false
export function redirectLoginFn(customErrorTips?: any) {
  // 防止多次跳转
  if (!redirectLoginState) {
    redirectLoginState = true
    // custId存在，跳转数字化管理系统登录页
    const custId =
      getQueryString('custId', location.href) || ls.get('user_cust_id')
    store
      .dispatch('user/resetToken')
      .then(() => {})
      .finally(() => {
        // customErrorTips('请求错误,您的登录信息失效,请重新登录!')
        redirectLoginState = false
        if (custId) {
          window.open(
            `${location.origin}${process.env.VUE_APP_LOWCODE_URL}`,
            '_self',
          )
        } else {
          window.open(`${process.env.BASE_URL}login`, '_self')
        }
      })
  }
  return true
}

export const redirectLogin = (to: any, customErrorTips: any) => {
  return (res: AxiosResponse) => {
    const {
      data: { status },
    } = res
    if (['52118', '52119', '52120'].includes(String(status))) {
      return redirectLoginFn(customErrorTips)
    }
    return false
  }
}
