

































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import PlanInfoModal from './components/PlanInfoModal/index.vue'
import EvaluateModal from './components/EvaluateModal/index.vue'
import PlanList from './components/PlanList/index.vue'
import SearchPlanList from './components/SearchPlanList/index.vue'
import DraftPlanList from './components/DraftPlanList/index.vue'

import {
  dailyPlanList,
  editDailyPlanProcess,
  exportDailyPlan,
  getCalendarEvent,
  delPlan,
  dailyPlanStat,
  editDailyPlanSubmit,
} from '@/api/dailyPlan'

@Component({
  components: {
    PlanInfoModal,
    EvaluateModal,
    PlanList,
    SearchPlanList,
    DraftPlanList,
  },
})
export default class DailyPlan extends Vue {
  private keyword = ''
  protected currentDate = moment()
  protected displayDate = moment()

  currentState = 'all'

  get currentDateStr() {
    const week = ['一', '二', '三', '四', '五', '六', '日']
    return (
      this.currentDate.format('YYYY年M月DD日  周') +
      week[this.currentDate.isoWeekday() - 1]
    )
  }

  headerRender({ value }: any) {
    return (
      <div class="calenday-title" style={{ padding: '10px' }}>
        <a-row type="flex" justify="space-between">
          <a-col>{value.format('YYYY.MM')}</a-col>
          <a-col>
            <a-icon
              type="left"
              onClick={() => this.handleDisplayDateChange('subtract')}
            />
            <a-icon
              type="right"
              style="margin-left:15px"
              onClick={() => this.handleDisplayDateChange('add')}
            />
          </a-col>
        </a-row>
      </div>
    )
  }

  handleDisplayDateChange(
    oprateType: 'add' | 'subtract',
    unit: 'months' | 'days' = 'months',
  ) {
    const cloneDate = this.displayDate.clone()
    if (oprateType === 'add') {
      this.displayDate = cloneDate.add(1, unit)
    } else {
      this.displayDate = cloneDate.subtract(1, unit)
    }
  }

  handleCurrentDateChange(
    oprateType: 'add' | 'subtract',
    unit: 'months' | 'days' = 'days',
  ) {
    const cloneDate = this.currentDate.clone()
    if (oprateType === 'add') {
      this.displayDate = this.currentDate = cloneDate.add(1, unit)
    } else {
      this.displayDate = this.currentDate = cloneDate.subtract(1, unit)
    }
  }

  onSelectDate() {
    console.log('onSelectDate')
    this.keyword = ''
    this.currentDate = this.displayDate
  }

  resetCurrentDate() {
    if (!moment().isSame(this.currentDate, 'day')) {
      this.currentDate = this.displayDate = moment()
    }
    if (!moment().isSame(this.displayDate, 'day')) {
      this.displayDate = moment()
    }
  }

  private loading: boolean = false
  private draftLoading: boolean = false
  private planList: any[] = []
  private draftPlanList: any[] = []

  @Watch('currentDate')
  queryAllPlanList() {
    this.keyword = ''
    this.queryPlanList()
    this.queryDraftPlanList()
  }

  queryPlanList() {
    this.keyword = ''
    this.loading = true
    setTimeout(async () => {
      const [err, res] = await dailyPlanList({
        planDate: this.currentDate.format('YYYY-MM-DD'),
        workPlan: '',
        isSubmit: 1,
      })
      this.loading = false
      if (err) return
      this.planList = res
    }, 300)
  }

  queryDraftPlanList() {
    this.keyword = ''
    this.draftLoading = true
    setTimeout(async () => {
      const [err, res] = await dailyPlanList({
        planDate: this.currentDate.format('YYYY-MM-DD'),
        workPlan: '',
        isSubmit: 0,
      })
      this.draftLoading = false
      if (err) return
      this.draftPlanList = res
    }, 300)
  }

  private calendarEvents: any[] = []
  @Watch('displayDate')
  async initCalendarEvent(oval: any, nval: any) {
    const oldStr = oval?.format('YYYY-MM') ?? ''
    const newStr = nval.format('YYYY-MM')
    if (oldStr === newStr) return
    const [err, res] = await getCalendarEvent(
      this.displayDate.format('YYYY-MM'),
    )
    if (err) return
    this.calendarEvents = res
  }

  planInfo: any = {}
  mode: string = 'add'
  planInfoModalVisible: boolean = false

  // 添加
  handleAdd() {
    this.mode = 'add'
    this.planInfo = {}
    this.planInfoModalVisible = true
  }

  // 详情
  handlePreview(record: any) {
    this.mode = 'preview'
    this.planInfo = record
    this.planInfoModalVisible = true
  }

  handleEdit(record: any) {
    this.mode = 'edit'
    this.planInfo = record
    this.planInfoModalVisible = true
  }

  handleSubmit(record: any) {
    const { id } = record
    this.$confirm({
      content: '确定要提交当前计划?',
      onOk: async () => {
        const [err] = await editDailyPlanSubmit(id, 1)
        if (!err) {
          this.$message.success('提交成功!')
          this.queryAllPlanList()
          this.queryStatInfo()
        }
      },
    })
  }

  handleDelete(record: any) {
    this.$confirm({
      content: '确定要删除当前计划?',
      onOk: async () => {
        const [err] = await delPlan(record.id)
        if (!err) {
          this.$message.success('删除成功!')
          this.queryDraftPlanList()
        }
      },
    })
  }

  // 修改进度
  processVisible: boolean = false
  processLoading: boolean = false
  processNum: number = 0
  handleEditProcess(record: any) {
    this.planInfo = record
    this.processNum = record.finishProgress || 1
    this.processVisible = true
  }
  async submitProcess() {
    const { id } = this.planInfo
    this.processLoading = true
    const [err] = await editDailyPlanProcess(id, this.processNum)
    this.processLoading = false
    if (err) return
    this.$message.success('修改成功')
    this.processVisible = false
    this.queryPlanList()
  }

  // 自我评价
  evaluateVisible: boolean = false
  handleEvaluate(record: any) {
    this.planInfo = record
    this.evaluateVisible = true
  }

  exportLoading: boolean = false
  async handleExport() {
    this.exportLoading = true
    await exportDailyPlan({
      planDate: this.currentDate.format('YYYY-MM-DD'),
      workPlan: '',
    })
    this.exportLoading = false
  }

  statInfo: any = {
    currentMonthPlanCount: 0,
    currentMonthWordCount: 0,
    lastMonthPlanCount: 0,
  }
  async queryStatInfo() {
    const [err, res] = await dailyPlanStat()
    if (err) return
    this.statInfo = res
  }

  refreshPage(reloadEvent = false) {
    if (reloadEvent) {
      this.initCalendarEvent(null, this.displayDate)
      this.queryStatInfo()
    }
    this.queryAllPlanList()
  }

  mounted() {
    this.initCalendarEvent(null, this.displayDate)
    this.queryAllPlanList()
    this.queryStatInfo()
  }
}
