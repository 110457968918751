



































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { getYearPlanList } from '@/api/year'
import moment from 'moment'

const startYear = ['prod', 'prodcce', 'dalu-prod'].includes(
  process.env.VUE_APP_MODE,
)
  ? moment('2024')
  : moment('2023')

@Component({
  components: {},
})
export default class Year extends Vue {
  @Ref('carouselRef')
  private carouselRef: any

  private yearList: any = []

  created() {
    let tempYear = startYear.clone()
    const currentYear = moment().startOf('year')

    while (tempYear.isBefore(currentYear)) {
      this.yearList.push({ year: tempYear.format('YYYY'), disabled: true })
      tempYear = tempYear.add(1, 'year')
    }

    // 今年
    this.yearList.push({ year: tempYear.format('YYYY') })
    // 明年
    this.yearList.push({ year: tempYear.add(1, 'year').format('YYYY') })
    this.currentIndex = this.yearList.length - 1
    this.getCurrentYearList()
  }
  mounted() {
    setTimeout(() => {
      this.carouselRef.goTo(this.currentIndex - 1, true)
    }, 500)
  }

  createYear(year: string) {
    this.$router.push({
      path: '/year/addYearPlan',
      query: { createYear: year },
    })
  }

  goShowYearPlan(year: string) {
    this.$router.push({ path: '/year/showYearPlan', query: { year: year } })
  }

  currentIndex = 1
  get displayYearList() {
    return this.currentIndex > 1
      ? [this.yearList[this.currentIndex - 1], this.yearList[this.currentIndex]]
      : [this.yearList[0], this.yearList[1]]
  }
  handleCurrentChange(current: number) {
    this.currentIndex = current + 1
  }

  async getCurrentYearList() {
    const [err, res] = await getYearPlanList()
    if (err) return
    res.forEach((item: any, index: number) => {
      const sameYearIndex = this.yearList.findIndex(
        (year: any) => year.year == item.year,
      )
      if (sameYearIndex < 0) return
      this.$set(this.yearList, sameYearIndex, {
        ...item,
        disabled: this.yearList[sameYearIndex].disabled,
      })
    })
  }
}
