







































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import { getPersonPlanList, exportPersonStatisList } from '@/api/statistics'
import { cloneDeep } from 'lodash-es'
import moment from 'moment'

const defaultParam = {
  time: [
    moment().subtract(14, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ],
  member: [],
}

@Component({
  components: {},
})
export default class DailyPlan extends Vue {
  // 计划类型 1年计划 2月计划 3周计划 4日计划
  private planType: number = 4
  private messageTip = '默认展示最近15天数据'
  private moment: any = moment
  @Ref() readonly table!: any
  private dataType: string = '1'
  private columns = [
    {
      title: '日期',
      dataIndex: 'timePeriod',
      with: '20%',
      scopedSlots: { customRender: 'timePeriod' },
    },
    {
      title: '分享占比（%）',
      dataIndex: 'submitScale',

      scopedSlots: { customRender: 'submitScale' },
    },
    {
      title: '已分享人数（个）',
      dataIndex: 'submitNumber',
      scopedSlots: { customRender: 'submitNumber' },
    },
    {
      title: '未分享人数（个）',
      dataIndex: 'unSubmitNumber',
      scopedSlots: { customRender: 'unSubmitNumber' },
    },
    {
      title: '未分享人',
      dataIndex: 'unSubmitUsers',
      scopedSlots: { customRender: 'unSubmitUsers' },
    },
  ]
  private queryParam: any = { time: [], member: [] }
  private apiFun(params: any) {
    return getPersonPlanList(params)
  }
  setParams() {
    const { time = [], member = [] } = this.queryParam
    // debugger
    return {
      startTime: time[0] ?? '',
      endTime: time[1] ?? '',
      planType: this.dataType,
      userIds: member.map((item: any) => item.receiverId).join(),
    }
  }
  mounted() {
    this.queryParam = { ...cloneDeep(defaultParam) }
  }
  private onChange(e: any) {
    if (e.target.value !== '1') {
      this.queryParam.time = this.getLastWeekDates
      // console.log(this.queryParam.time)
      this.messageTip = '默认展示上周数据'
    } else {
      this.queryParam.time = [
        moment().subtract(14, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ]
      this.messageTip = '默认展示近15天的数据'
    }

    // this.setParams()
    this.dataType = e.target.value
    this.table.refresh(true)
  }

  // 查询
  @Watch('queryParam.time')
  refreshTable(needRefreshTable: boolean = true) {
    this.table.refresh(needRefreshTable)
  }
  @Watch('queryParam.member')
  refreshTableData() {
    // console.log(this.queryParam)
    // this.queryParam.userIds = this.queryParam.member.map((v:any) => {
    //   return v.receiverId
    // })
    //  this.queryParam = { ...cloneDeep(defaultParam) }
    // this.queryParam = { ...defaultParam }
    this.table.refresh(true)
  }

  // 详情
  detailModalVisible: boolean = false
  planInfo: any = {}
  customRow(record: any, index: string) {
    return {
      on: {
        click: () => {
          // this.planInfo = record
          this.planInfo = {
            ...record,
          }
          this.detailModalVisible = true
        },
      },
    }
  }

  exportLoading: boolean = false
  async handleExport() {
    // this.exportLoading = true
    const params = this.setParams()
    console.log(params)
    // 计划类型 1年计划 2月计划 3周计划 4日计划
    const fileName: any = this.dataType === '1' ? '日计划统计' : '周计划统计'
    await exportPersonStatisList(params, fileName)
    this.exportLoading = false
  }
  get getLastWeekDates() {
    var today = moment()
    var lastWeekStart = today
      .clone()
      .subtract(1, 'weeks')
      .startOf('week')
      .format('YYYY-MM-DD')
    var lastWeekEnd = today
      .clone()
      .subtract(1, 'weeks')
      .endOf('week')
      .format('YYYY-MM-DD')

    var datesArray = [lastWeekStart, lastWeekEnd]

    return datesArray
  }
}
