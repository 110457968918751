import NaturalReportLayout from '@/layout/NaturalReportLayout.vue'

export default {
  path: '/natural-report',
  name: 'NaturalReport',
  component: NaturalReportLayout,
  meta: { title: '' },
  redirect: '/natural-report/index',
  children: [
    {
      path: '/natural-report/index',
      component: () => import('@/views/naturalPersonReport/report/index.vue'),
      meta: {
        title: '汇报',
      },
    },
    {
      path: '/natural-report/stat',
      component: () => import('@/views/naturalPersonReport/stat/index.vue'),
      meta: {
        title: '统计',
      },
    },
  ],
}
